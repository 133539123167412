<template>
  <div class="header-bg" :class="{ 'bg-white': isExploreMenuVisible }">
    <div :class="{ 'header-shadow': isExploreMenuVisible }">
      <div class="container">
        <nav class="navbar row py-0 px-0">
          <div class="col d-flex align-items-center">
            <NavLogo class="mr-3 mr-lg-4 px-0" :showWhiteLogo="isTransparentBg" />
            <button
              :class="{
                'collapsed': isExploreMenuVisible,
                'btn-translucent-dark no-invert': isTransparentBg,
                'btn-light': !isTransparentBg,
              }"
              :aria-expanded="isExploreMenuVisible ? 'true' : 'false'"
              class="btn btn-explore-toggle text-nowrap"
              data-qa="home-page-nav-explore-btn-mobile"
              @click.stop="isExploreMenuVisible = !isExploreMenuVisible"
            >
              <font-awesome-icon :icon="icons.faBars" class="mx-1" />
              {{ $t('nav.explore')}}
            </button>
            <NuxtClientOnly>
              <div v-if="showStudioAiLink" class="d-none d-lg-block ml-3 mr-n3">
                <a
                 :href="studioAiLink"
                 class="text-nowrap d-inline-flex align-items-center px-3 nav-link"
                 :class="{ 'text-white no-invert': isTransparentBg }"
                >
                  {{ $t('nav.ai_tools') }}
                  <span class="ml-2 text-base text-primary">
                    <font-awesome-icon :icon="icons.faSparkle" />
                  </span>
                </a>
              </div>
            </NuxtClientOnly>
            <NuxtClientOnly>
              <div class="overflow-hidden">
                <NavRecentlyVisitedList
                  :isTransparent="isTransparentBg"
                  :showItems="showStudioAiLink ? 2 : 3"
                  class="ml-3 d-none d-xl-block"
                />
              </div>
            </NuxtClientOnly>
              <!--
              <div>
                <NavLink
                  v-if="showUnlimitedSubscriptionLink"
                  to="/subscribe"
                  class="ml-3 px-3 nav-link"
                >
                  {{ $t('term.unlimited_subscription') }}
                </NavLink>
                <div class="overflow-hidden">
                  <NavRecentlyVisitedList
                    :isTransparent="isTransparentBg"
                    class="ml-3 d-none d-xl-block"
                  />
                </div>
              </div>-->
          </div>
          <NuxtClientOnly class="col-auto d-flex align-items-center justify-content-end">
            <div>
<!--              <NavLink-->
<!--                  v-if="showUnlimitedSubscriptionLink"-->
<!--                  to="/subscribe"-->
<!--                  class="btn btn-custom-link px-2 position-relative text-primary"-->
<!--              >-->
<!--                {{ $t('term.unlimited_subscription') }}-->
<!--              </NavLink>-->

              <NavLink
                v-if="isLoggedIn"
                :to="$link('/library/favorites').url()"
                repo="frontend-search"
                class="btn btn-custom-link px-2 position-relative"
                :class="{ 'text-white no-invert': isTransparentBg }"
                v-tooltip="$t('header.favorites')"
              >
                <font-awesome-icon :icon="icons.faHeart" />
                <span
                  v-if="addedToday.length"
                  class="badge badge-primary p-0 rounded-circle badge-dot"
                ></span>
              </NavLink>
              <CartNavDropdown
                v-if="isLoggedIn"
                data-qa="home-page-nav-cart-dropdown"
                :variant="isTransparentBg ? 'custom-link px-2 text-white no-invert' : 'custom-link px-2'"
                @onShow="isExploreMenuVisible = false"
              />

              <MenuLanguageNavDropdown
                class="mr-2 mr-lg-3"
                data-qa="home-page-nav-lang-dropdown"
                :variant="isTransparentBg ? 'custom-link text-white no-invert' : 'custom-link'"
                @onShow="isExploreMenuVisible = false"
              />

              <UserNavDropdown
                v-if="isLoggedIn"
                data-qa="home-page-nav-user-dropdown"
                :variant="isTransparentBg ? 'outline-translucent-dark no-invert' : 'simple-secondary'"
                @onShow="isExploreMenuVisible = false"
              />
              <button
                v-else
                class="btn"
                :class="{
                  'btn-outline-translucent-dark no-invert': isTransparentBg,
                  'btn-simple-secondary': !isTransparentBg,
                }"
                @click="goToLogin()"
              >
                {{ $t('header.login') }}
              </button>
            </div>
          </NuxtClientOnly>
        </nav>
      </div>
    </div>
    <NuxtClientOnly>
      <b-collapse v-model="isExploreMenuVisible" class="explore-menu-collapse bg-white">
        <ExploreMenu v-click-outside="hideExploreMenu" />
      </b-collapse>
    </NuxtClientOnly>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mapState, mapGetters } from 'vuex';

import {
  faHeart,
  faSignIn,
  faBars,
  faGlobe,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faUser,
  faShoppingCart,
} from '@motionelements/assets/src/js/me-font/icons-fa6';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { faSparkle } from '@fortawesome/pro-solid-svg-icons';

import featureFlagMixin from '@motionelements/core/src/components/mixins/feature-flag.mixin.js';
import authMixin from '@motionelements/core/src/components/mixins/auth.mixin.js';
import studioAiMixin from '@motionelements/core/src/components/mixins/studio-ai.mixin.js';

import NavRecentlyVisitedList from '@motionelements/core/src/components/nav/NavRecentlyVisitedList.vue';
import NavLink from '@motionelements/core/src/components/nav/NavLink.vue';
import NavLogo from './NavLogo.vue';
import MenuLanguageNavDropdown from './MenuLanguageNavDropdown.vue';
import UserNavDropdown from './UserNavDropdown.vue';
import CartNavDropdown from './CartNavDropdown.vue';

export default {
  name: 'TheNavBarDesktop',
  props: {
    isTransparent: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [featureFlagMixin, authMixin, studioAiMixin],
  directives: {
    ClickOutside,
  },
  components: {
    NuxtClientOnly: () => import('./NuxtClientOnly.vue'),
    // NavLink: () => import('./NavLink.vue'),
    ExploreMenu: () => import('./ExploreMenu.vue'),
    NavLink,
    NavLogo,
    MenuLanguageNavDropdown,
    UserNavDropdown,
    CartNavDropdown,
    NavRecentlyVisitedList,
  },
  data() {
    return {
      isExploreMenuVisible: false,
      icons: {
        faBars,
        faHeart,
        faTimes,
        faSignIn,
        faGlobe,
        faShoppingCart,
        faUser,
        faSparkle,
      },
    };
  },
  watch: {
    '$route'() {
      // console.log('========== WATCH $route ', val, oldVal);
      this.isExploreMenuVisible = false;
    },
  },
  computed: {
    ...mapState({
      // favoritesDefaultCount: state => state.favorites && state.favorites.defaultFolder.totalCount,
      isLoggedIn: state => state.user.isLoggedIn,
      addedToday: state => state.favorites.addedToday,
    }),
    ...mapGetters({
      isMember: 'user/isMember',
    }),
    isTransparentBg() {
      return this.isTransparent && !this.isExploreMenuVisible;
    },
    showUnlimitedSubscriptionLink() {
      return !this.isLoggedIn;
    },
  },
  methods: {
    hideExploreMenu() {
      this.isExploreMenuVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  flex-wrap: nowrap;
}
.header .btn-explore-toggle {
  min-width: 130px;
  flex: 0 0 auto;
}
.header-bg {
  .ui-dark & {
    transition: all .1s 0.3s;
    &.bg-white {
      transition: all 0s;
    }
  }
}
.header-shadow {
  box-shadow: 0px 4px 48px rgba(1, 3, 20, 0.16);
  // show shadow when explore menu open
  position: relative;
  z-index: 1001;
}

.explore-menu-collapse {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
}
.btn-light {
  &.collapsed {
    background-color: map-get($grays, '200');
    border-color: map-get($grays, '200');
    .ui-dark & {
      background-color: map-get($grays-dark, '200');
      border-color: map-get($grays-dark, '200');
    }
  }
}
</style>
