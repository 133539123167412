import { mapState, mapActions } from 'vuex';
import * as teleport from '@motionelements/teleport';
import { isProductType } from '@motionelements/core/src/services/catalog.service.js';
import { downloadSample } from '@motionelements/core/src/api/downloads.js';
import notificationService from '@motionelements/core/src/services/notification.service.js';

export default {
  mounted() {
    this.$bus.$on('product-favorite', this.onProductFavorite);
    this.$bus.$on('product-favorited', this.onProductFavorite);
    this.$bus.$on('product-pin', this.onProductPin);
    this.$bus.$on('product-similar-search', this.onProductFilterSimilar);
    this.$bus.$on('product-sample-download', this.onProductSampleDownload);

    // teleport events
    this.$bus.$on('product-previewed', this.teleportProductPreviewedEvent);
    this.$bus.$on('product-selected', this.teleportProductSelectedEvent);
    this.$bus.$on('product-similar-searched', this.teleportProductSimilarSearched);
    this.$bus.$on('product-viewed', this.teleportProductViewedEvent);
    this.$bus.$on('product-licensed', this.teleportProductLicensedEvent);
    this.$bus.$on('product-downloaded', this.teleportProductDownloadedEvent);
    this.$bus.$on('product-added-to-cart', this.$_teleportProductAddedToCartEvent);
    this.$bus.$on('product-removed-from-cart', this.$_teleportProductRemovedFromCartEvent);
    this.$bus.$on('order-completed-event', this.$_teleportOrderCompletedEvent);

    // this.$bus.$on('cart-viewed', this.teleportEvent);

    // this.$bus.$on('product-video-player-load-play', this.onProductPlayed);
    // this.$bus.$on('product-video-player-pause-unload', this.onProductStopped);
    // this.$bus.$on('product-audio-player-play', this.onProductPlayed);
    // this.$bus.$on('product-audio-player-pause', this.onProductStopped);
    // this.$bus.$on('product-audio-player-progress', this.onProductProgress);
  },
  computed: {
    ...mapState({
      // queryId: state => state.search.queryId,
      isLoggedIn: state => state.user.isLoggedIn,
    }),
  },
  beforeDestroy() {
    this.$bus.$off('product-favorite', this.onProductFavorite);
    this.$bus.$off('product-pin');
    this.$bus.$off('product-favorited', this.onProductFavorite); // @legacy
    this.$bus.$off('product-similar-search', this.onProductFilterSimilar);
    this.$bus.$off('product-sample-download', this.onProductSampleDownload);

    this.$bus.$off('product-previewed', this.teleportProductPreviewedEvent);
    this.$bus.$off('product-selected', this.teleportProductSelectedEvent);
    this.$bus.$off('product-similar-searched', this.teleportProductSimilarSearched);
    this.$bus.$off('product-viewed', this.teleportProductViewedEvent);
    this.$bus.$off('product-licensed', this.teleportProductLicensedEvent);
    this.$bus.$off('product-downloaded', this.teleportProductDownloadedEvent);
    this.$bus.$off('product-added-to-cart', this.$_teleportProductAddedToCartEvent);
    this.$bus.$off('product-removed-from-cart', this.$_teleportProductRemovedFromCartEvent);
    this.$bus.$off('order-completed-event', this.$_teleportOrderCompletedEvent);
    // this.$bus.$off('cart-viewed', this.teleportEvent);

    // this.$bus.$off('product-video-player-load-play', this.onProductPlayed);
    // this.$bus.$off('product-video-player-pause-unload', this.onProductStopped);
    // this.$bus.$off('product-audio-player-play', this.onProductPlayed);
    // this.$bus.$off('product-audio-player-pause', this.onProductStopped);
    // this.$bus.$off('product-audio-player-progress', this.onProductProgress);
  },
  methods: {
    ...mapActions({
      PIN_UNPIN_FOLDER_ITEM: 'library/PIN_UNPIN_FOLDER_ITEM',
    }),
    buildEventData(data) {
      // if (this.queryId) {
      if (_.has(data, 'product')) {
        const queryId = _.get(data, 'product.metadata.queryId');
        if (queryId) {
          _.set(data, 'search.query_id', queryId);
        }

        // if single products
        const position = _.get(data, 'product.metadata.position');
        if (position) {
          _.set(data, 'search.position', position);
        }
      }
      // }
      return data;
    },
    onProductFavorite({ product }) {
      if (!this.isLoggedIn) {
        this.$store.dispatch('modal/showSignUp');
        return;
      }
      this.addFavoriteItem(product);
    },
    onProductPin({ product }) {
      this.PIN_UNPIN_FOLDER_ITEM(product)
        .then(() => {
          this.$bus.$emit('Library:update-content');
        })
        .catch((err) => {
          console.dir(err);
        });
    },
    addFavoriteItem(product) {
      console.log('addFavorites', product);
      if (!product) {
        return false;
      }
      this.$store.commit('favorites/updateAddedToday', product);
      this.$store.dispatch('favorites/addFavoriteItem', {
        productId: product.id,
      }).then((response) => {
        console.log('addFavorites done', product, response);
        const data = _.get(response, 'data.data');
        this.$store.commit('favorites/updateAddedToday', product);

        // update event only for new adds
        if (response.status === 201) {
          const eventData = this.buildEventData({
            timestamp: _.get(data, 'addedAt'),
            product: product,
          });
          teleport.event('product_favorited', eventData, this);
        }
      })
        .catch(err => {
          console.error(err);
          this.$store.commit('favorites/findAndRemoveFavoriteItemsFromAddedToday', [product.id]);
        });
    },
    onProductSampleDownload(payload) {
      const { product } = payload;

      let type = 'image';
      if (isProductType(['video', 'video_template'], product.mediaType)) {
        type = 'video';
      } else if (isProductType(['audio'], product.mediaType)) {
        type = 'audio';
      }
      // const type = getMediaTypeGroup(product.mediaType);
      // this.$store.dispatch('blocker/show');
      downloadSample(product.id, type)
        .then((response) => {
          this.teleportProductSampleDownloadedEvent(payload);
          window.location.href = response.data.data.url;
        })
        .catch(err => {
          console.error(err);
          notificationService.alert({
            level: 'danger',
            title: this.$t('error.failed'),
          });
        })
        .finally(() => {
          // this.$store.dispatch('blocker/hide');
        });
    },
    /*
    teleportEvent(type, product) {
      const timestamp = Number((Date.now() / 1000).toFixed(0));

      const productsInfo = [];
      productsInfo.push(product);

      const teleportInfo = {
        'timestamp': timestamp,
        'products': productsInfo,
      };

      // console.log(type, teleportInfo);
      teleport.event(type, teleportInfo, this);
    },
     */
    // onProductViewed(product) {
    //   this.teleportEvent('view-product', product);
    // },
    onProductFilterSimilar(payload) {
      const { product } = payload;
      this.$store.commit('search/setSimilarToProduct', product);
      console.log('onProductFilterSimilar', payload, product);

      const mediaType = _.get(product, 'mediaType');
      if (mediaType) {
        this.teleportProductSimilarSearched(payload);

        const isSearchRepo = process.env.VUE_APP_PROJECT_NAME && process.env.VUE_APP_PROJECT_NAME === 'frontend-search';

        const path = `/search/similar/${mediaType.replace('_', '-')}`;

        const query = { ...this.$route.query, sort: 'match', similar: product.id };
        delete query.similar_face_id;
        delete query.similar_type;
        delete query.page;
        if (query.s === query.similar) {
          delete query.s;
        }
        if (mediaType === 'music' && _.has(query, 's')) {
          delete query.s;
        }
        if (isSearchRepo) {
          this.$router.push({
            path: this.$link(path).fullPath(),
            query,
          });
          return;
        }
        window.location.href = this.$link(path).params(query).url();
      }
    },
    // TELEPORT
    teleportProductPreviewedEvent(payload) {
      teleport.event('product_previewed', this.buildEventData(payload), this);
    },
    teleportProductSampleDownloadedEvent(payload) {
      teleport.event('product_sample_downloaded', this.buildEventData(payload), this);
    },
    teleportProductSelectedEvent(payload) {
      teleport.event('product_selected', this.buildEventData(payload), this);
    },
    teleportProductViewedEvent(payload) {
      teleport.event('product_viewed', this.buildEventData(payload), this);
    },
    teleportProductLicensedEvent(payload) {
      teleport.event('product_licensed', this.buildEventData(payload), this);
    },
    teleportProductDownloadedEvent(payload) {
      teleport.event('product_downloaded', this.buildEventData(payload), this);
    },
    teleportProductSimilarSearched(payload) {
      teleport.event('product_similar_searched', this.buildEventData(payload), this);
    },
    $_teleportProductAddedToCartEvent(payload) {
      teleport.event('product_added_to_cart', this.buildEventData(payload), this);
    },
    $_teleportProductRemovedFromCartEvent(payload) {
      teleport.event('product_removed_from_cart', this.buildEventData(payload), this);
    },
    $_teleportOrderCompletedEvent(payload) {
      teleport.event('order_completed', this.buildEventData(payload), this);
    },
    // onProductPlayed(product) {
    //   console.log('onProductPlayed', product);
    // },
    // onProductStopped(product) {
    //   console.log('onProductStopped', product);
    // },
    // onProductProgress(product) {
    //   console.log('onProductProgress', product);
    // },
    // top bar actions methods
  },
};
